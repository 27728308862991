import { Navigate, useLocation } from 'react-router-dom';
import { Storage } from './utility/storage';

const PrivateRoute = ({
  children,
  roles,
}) => {
  let location = useLocation();
 
  const userHasRequiredRole = Storage.getSession().role && roles.includes(Storage.getSession().role) ? true : false;

  if (Storage.getAccessToken() === null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (Storage.getAccessToken() !== null && !userHasRequiredRole) {
    return 'Page Not Found.'; 
  }

  return children;
};

export default PrivateRoute;