import { handleApi } from "../utility";
import axiosClient from "../utility/axiosClient";

const BASE = `users`;

export async function getUsers(queryParams = "") {
  try {
    const result = await handleApi(axiosClient.get(`${BASE}?${queryParams}`));
    if (result.data && result.data.users) {
      return result.data.users;
    }

    return null;
  } catch (err) {
    console.log(err);
    return false;
  }
}
// export async function getUsers(search, pageIndex, pageLimit, verified, goated) {
//   return await handleApi(
//     axiosClient.get(
//       `${BASE}${
//         pageIndex !== null && pageLimit !== null
//           ? search !== ""
//             ? `?search=${search}&page=${pageIndex}&limit=${pageLimit}`
//             : `?page=${pageIndex}&limit=${pageLimit}${
//                 verified !== null && verified !== undefined
//                   ? `&filter_isVerified=` + verified
//                   : ""
//               }${
//                 goated !== null && goated !== undefined
//                   ? `&filter_isGoated=` + goated
//                   : ""
//               }`
//           : ``
//       }`
//     )
//   );
// }

export async function deleteUsers(id) {
  return await handleApi(axiosClient.delete(`${BASE}/${id}`));
}

export async function reactivateUser(id) {
  return await handleApi(axiosClient.put(`${BASE}/reactivate/${id}`));
}

export async function getLiveTakesById(id, pageIndex, pageLimit) {
  return await handleApi(
    axiosClient.get(
      `${BASE}/${id}/livetakes${
        pageIndex !== null && pageLimit !== null
          ? `?page=${pageIndex}&limit=${pageLimit}`
          : ``
      }`
    )
  );
}

export async function getUserLiveTakes(userId = null, queryParams = "") {
  try {
    const result = await handleApi(
      axiosClient.get(`${BASE}/${userId}/livetakes?${queryParams}`)
    );
    if (result.data && result.data.liveTakes) {
      return result.data.liveTakes;
    }

    return null;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getTakesById(id) {
  return await handleApi(axiosClient.get(`${BASE}/${id}/takes`));
}

export async function getUserTakes(userId = null, queryParams = "") {
  try {
    const result = await handleApi(
      axiosClient.get(`${BASE}/${userId}/takes?${queryParams}`)
    );
    if (result.data && result.data.takes) {
      return result.data.takes;
    }

    return null;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getLiveTakeCommentsById(id) {
  return await handleApi(axiosClient.get(`${BASE}/livetake-comments/${id}`));
}

export async function deleteLiveTakes(id) {
  return await handleApi(axiosClient.delete(`${BASE}/livetakes/${id}`));
}

export async function deleteTakes(id) {
  return await handleApi(axiosClient.delete(`${BASE}/takes/${id}`));
}

export async function changeStatus(id, data) {
  return await handleApi(axiosClient.put(`${BASE}/badge-status/${id}`, data));
}

export async function editUsername(id, username) {
  return await handleApi(
    axiosClient.put(`${BASE}/update-username/${id}`, { userName: username })
  );
}

export async function getUserById(id) {
  return await handleApi(axiosClient.get(`${BASE}/${id}`));
}

export async function deleteLiveTakesCommnetById(id) {
  return await handleApi(axiosClient.delete(`${BASE}/livetake-comments/${id}`));
}

export async function deleteTakesCommnetById(id) {
  return await handleApi(axiosClient.delete(`${BASE}/livetake-comments/${id}`));
}
