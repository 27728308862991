import { handleApi } from "../utility";
import axiosClient from "../utility/axiosClient";

const BASE = `auth`;

export async function logout() {
  return await handleApi(axiosClient.get(`${BASE}/logout`));
}

export async function login(data) {
  return await handleApi(axiosClient.post(`${BASE}/login`, data));
}

export async function forgotPassword(data) {
  return await handleApi(axiosClient.post(`${BASE}/forgot-password`, data));
}

export async function changePassword(data) {
  return await handleApi(axiosClient.post(`account/change-password`, data));
}

export async function autoLogin(data) {
  return await handleApi(axiosClient.post(`${BASE}/refresh-token`, data));
}



