import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowImg, LogoSvg, passwordRules } from "../../utility";
import { forgotPassword } from "../../services";
import { useForm } from "react-hook-form";
import "./LoginForm.scss";
import { Form } from "react-bootstrap";
import Input from "../../components/formComponents/Input";
import { isJwtExpired } from "jwt-check-expiration";
import { Storage } from "../../utility/storage";
import { toast } from "react-toastify";

const ForgotPassword = ({ ForgotPassword }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: passwordRules,
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if(Storage.getAccessToken() && !isJwtExpired(Storage.getAccessToken() )){
      navigate('/');
    }
  }, [navigate]);

  const onSubmit = (values) => {
    forgotPassword(values).then((response) => {
      if (response.success) {
       
        toast.success(response.data.message);
        navigate('/');
      } else {
        toast.success('User not found.');
      }
    });
  };

  return (
    // Forgot Password Page Layout
    <div className="login-pages">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inner">
          <div>
            <img src={LogoSvg} alt="logo" className="admin-logo" />
          </div>
          <h5 className="forgot-pwd-text">Forgot your password?</h5>
          <p className="forgot-pwd-desc">
            Enter your email address & we’ll <br />
            send you a <b className="fw-700">reset password link</b>
          </p>
          <div className="form-group">
            <Input
              type="text"
              name="email"
              id="email"
              placeholder={"Email"}
              {...register("email")}
              error={errors.email?.message}
            />
          </div>
          <button type="submit" className="login-button">
            <img src={ArrowImg} alt="arrow" className="arrow-style" />
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPassword;
