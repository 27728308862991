import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";
import csvDownload from "json-to-csv-export";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { toast } from "react-toastify";
import Avatar from "react-avatar";

import DataTable from "../../components/dataTable/DataTable";

import { getUsers } from "../../services/users";

const Users = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [refresh] = useState(0);
  const [downloadFile, setDownloadFile] = useState(false);

  const columns = useMemo(
    () => [
      {
        key: "name",
        title: "Name",
        type: "text",
        className: "text-truncate",
        custom: (item) => {
          return (
            <span className="d-flex align-items-center">
              <Avatar
                name={`${
                  item.profilePhoto
                    ? `${process.env.REACT_APP_S3}${item.profilePhoto}`
                    : item.userName
                }`}
                src={`${
                  item.profilePhoto
                    ? `${process.env.REACT_APP_S3}${item.profilePhoto}`
                    : null
                }`}
                size="32"
                round={true}
              />
              <span className="my-0 ms-2">{`${
                item.firstName ? item.firstName : ""
              } ${item.lastName ? item.lastName : ""}`}</span>
            </span>
          );
        },
      },
      {
        key: "username",
        title: "Username",
        type: "text",
        className: "text-truncate",
        custom: (item) => {
          return (
            <span className="d-flex align-items-center">
              {item.userName}{" "}
              {item.isVerified && <span className="verified-badge ms-1 mt-1 active"></span>}
            </span>
          );
        },
      },
      {
        key: "isVerified",
        title: "Is Verified?",
        type: "text",
        className: "text-truncate",
        custom: (item) => <span>{item.isVerified ? "Yes" : "No"}</span>,
      },
      {
        key: "isGoated",
        title: "Is Goated?",
        type: "text",
        className: "text-truncate",
        custom: (item) => <span>{item.isGoated ? "Yes" : "No"}</span>,
      },
      {
        key: "followerCount",
        title: "Followers",
        type: "text",
      },
      {
        key: "followingCount",
        title: "Followings",
        type: "text",
      },
      {
        key: "createdAt",
        title: "Date Joined",
        type: "date",
      },
    ],
    []
  );

  const downloadCSV = useCallback(() => {
    setDownloadFile(true);
    getUsers().then((res) => {
      if (res.totalDocs && res.totalDocs > 0) {
        const dataToConvert = {
          data: res.docs,
          filename: "user-list" + new Date(),
          delimiter: ",",
        };
        csvDownload(dataToConvert);
      } else {
        toast.info("No users found.");
      }
      setDownloadFile(false);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col className="mb-2 d-flex align-items-center justify-content-start">
          <h4 className="fw-700">
            Total Users: <span style={{ color: "#39B54A" }}>{total}</span>
          </h4>
          <div className="d-flex align-items-center justify-content-end ms-auto">
            <Button
              className="d-flex align-items-center ms-3"
              onClick={() => downloadCSV()}
              disabled={downloadFile}
            >
              <AiOutlineCloudDownload className="me-2" />
              Export
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            columns={columns}
            setTotal={(total) => setTotal(total)}
            refresh={refresh}
            fetchData={getUsers}
            searchable={true}
            filters={[
              {
                name: "filter_isVerified",
                type: "select",
                placeholder: "Verified Status",
                options: [
                  { value: true, text: "Verified" },
                  { value: false, text: "Unverified" },
                ],
              },
              {
                name: "filter_isGoated",
                type: "select",
                placeholder: "Goated Status",
                options: [
                  { value: true, text: "Goated" },
                  { value: false, text: "Not Goated" },
                ],
              },
            ]}
            onRowClick={(value) => navigate(`/users/detail/${value._id}`)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
