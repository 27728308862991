const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token'
const EMAIL_ID = 'email_Id';
const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const ROLE = 'role';
export class Storage{
    static removeTokens(){

    }

    static setSession(data){

        const access_token = JSON.stringify(data.tokens.accessToken);
        const refresh_token = JSON.stringify(data.tokens.refreshToken);
        const email_Id = JSON.stringify(data.admin.email);
        const firstName = JSON.stringify(data.admin.firstName);
        const lastName = JSON.stringify(data.admin.lastName);
        const role = JSON.stringify(data.admin.role);
        localStorage.setItem(EMAIL_ID , email_Id);
        localStorage.setItem(ACCESS_TOKEN , access_token);
        localStorage.setItem(REFRESH_TOKEN , refresh_token); 
        localStorage.setItem(FIRST_NAME , firstName); 
        localStorage.setItem(LAST_NAME , lastName); 
        localStorage.setItem(ROLE , role);   
    }

    static getSession(){
       const data = {
        emailId : JSON.parse(localStorage.getItem(EMAIL_ID)),
        accessToken: JSON.parse(localStorage.getItem(ACCESS_TOKEN)),
        refreshToken : JSON.parse(localStorage.getItem(REFRESH_TOKEN)),
        firstName : JSON.parse(localStorage.getItem(FIRST_NAME)),
        lastName : JSON.parse(localStorage.getItem(LAST_NAME)),
        role : JSON.parse(localStorage.getItem(ROLE))
       }
       return data;
    }

    static removeSession(){
        localStorage.clear();
    }

    static getAccessToken(){
        try{
            return JSON.parse(localStorage.getItem(ACCESS_TOKEN));
        }catch(e){
            return null;
        }
    }

    static getRefreshToken(){
        try{
            return JSON.parse(localStorage.getItem(REFRESH_TOKEN));
        }catch(e){
            return null;
        }
    }
}