import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
const ColumnChart = ({ title, name, handelData,categories=[],data=[] , type}) => {
  const [change, setChange] = useState(type);
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    subtitle: false,
    xAxis: {
      categories: categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Active User",
      },
    },
    credits: {
      enabled: false,
      },
    tooltip: {
      borderWidth:1,
      headerFormat: '<p>Active User</p>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: name,
        data:data,
      },
    ],
  };

  const handelChange = (e) => {
    // console.log(e.target.value);
    setChange(e.target.value);
    handelData(e.target.value);
  };


  return (
    <div className="position-relative">
      <div className="active-user-dropdown">
      <div className="select-dropdown">
        <select onChange={handelChange} value={change} >
          <option value='daily'>Daily</option>
          <option value='weekly'>Weekly</option>
          <option value='monthly'>Monthly</option>
        </select>
      </div>
      </div>
      <div className="active-user-chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default ColumnChart;
