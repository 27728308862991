import React, { useCallback, useEffect, useState } from "react";
import "./Home.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { activeUsers, totalUsers } from "../../services/dashboard";
import { Container, Card, Col, Row, Spinner } from "react-bootstrap";
import PieCharts from "../../components/charts/PieCharts";
import ColumnChart from "../../components/charts/ColumnChart";
import moment from "moment";

const Home = () => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeUserData, setActiveUserData] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [type , setType] = useState('daily');
  const getTotalUsers = useCallback(() => {
    const arr = [];
    setLoading(true);
    totalUsers().then((res) => {
      if (res.api_success) {
        res.data.forEach((i) => {
          arr.push([i.name, i.value]);
        });
        setChartData(arr);
      }
      setLoading(false);
    });
  }, []);

  const getActiveUsers = useCallback((fromDate, toDate, value) => {
    setActiveLoading(true);
    activeUsers(fromDate, toDate, value).then((res) => {
      if (res.api_success) {
        setActiveUserData(res.data);
      }
      setActiveLoading(false);
    });
  }, []);

  useEffect(() => {
    let date = moment().format("YYYY-MM-DD");
    getTotalUsers();
    getActiveUsers('2022-12-01', date, "daily");
  }, [getTotalUsers, getActiveUsers]);

  const getData = (value) => {
    let fromDate = "2022-12-01";
    let toDate = moment().format("YYYY-MM-DD");
    // if (value === "daily") {
    //   fromDate = moment().format("YYYY-MM-DD");
    // }
    // if (value === "weekly") {
    //   fromDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
    // }
    // if (value === "monthly") {
    //   fromDate = moment().startOf("month").format("YYYY-MM-DD");
    // }
    setType(value);
    getActiveUsers(fromDate, toDate, value);
  };

  return (
    <Container>
      <Row>
        <Col md={4}>
          <Card className="border-0">
            {loading ? (
              <div className="py-5 d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PieCharts
                title="Total Users in LiveTake"
                seriesTitle="Users"
                data={chartData}
              />
            )}
          </Card>
        </Col>
        <Col md="8">
          <Card className="border-0">
            {activeLoading ? (
              <div className="py-5 d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <div>
                {activeUserData !== null && (
                  <ColumnChart
                    title="Total Active User"
                    name="Users"
                    handelData={getData}
                    type={type}
                    categories={
                      activeUserData.xAxis !== undefined
                        ? activeUserData.xAxis
                        : []
                    }
                    data={
                      activeUserData.yAxis !== undefined
                        ? activeUserData.yAxis
                        : []
                    }
                  />
                )}
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
