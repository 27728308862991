import React, { useState } from "react";
import { Image, Pagination } from "react-bootstrap";
import { usePagination, useTable, useSortBy } from "react-table";
import {
  BackArrowIcon,
  formatDate,
  NextArrowIcon,
  SortIconImg,
} from "../../utility";
import Loader from "../loader";

const TableComponent = ({
  fetchData,
  data,
  columns,
  loading,
  pageCount: controlledPageCount,
}) => {
  const [pages, setPages] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },

    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    console.log("useEffect", pageIndex, pageSize);
    if (pageIndex > 0) {
      fetchData({ pageIndex, pageSize });
    }
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, []);

  React.useEffect(() => {
    let pageArr = [];
    for (let i = 0; i < controlledPageCount; i++) {
      pageArr.push({ label: i + 1, value: i + 1 });
    }
    setPages(pageArr);
  }, [controlledPageCount]);

  return (
    <div
      className="position-relative "
      style={{ height: "calc(100vh - 280px)" }}
    >
      <table {...getTableProps()} style={{ tableLayout: "fixed" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.sorting ? (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    width={column.width !== 150 ? column.width : "auto"}
                  >
                    <div className="cursor">
                      {column.render("Header")}
                      <Image src={SortIconImg} className="img-fluid mx-2" />
                    </div>
                  </th>
                ) : (
                  <th
                    className={
                      column.render("Header") === " "
                        ? "heading p-0"
                        : "heading"
                    }
                    width={column.width !== 150 ? column.width : "auto"}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        width={cell.column.width ? cell.column.width : "auto"}
                        className={cell.column.className}
                        onClick={() =>
                          cell.column.onClick && cell.column.onClick(cell)
                        }
                        title={
                          cell.column.type === "date"
                            ? cell.value
                              ? formatDate(cell.value)
                              : "N/A"
                            : cell.value
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : loading ? (
            <tr style={{ borderRadius: "0px 0px 10px 10px" }}>
              <td
                style={{ borderRadius: "0px 0px 10px 10px" }}
                colSpan={columns.length}
                className="text-center"
              >
                <Loader />
              </td>
            </tr>
          ) : (
            !loading &&
            page.length === 0 && (
              <tr style={{ borderRadius: "0px 0px 10px 10px" }}>
                <td
                  style={{ borderRadius: "0px 0px 10px 10px" }}
                  colSpan={columns.length}
                  className="text-center"
                >
                  No data found.
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      {canPreviousPage || canNextPage ? (
        <Pagination className=" mt-5 mb-2 pb-3 ">
          <Pagination.Prev
            className="mx-3"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <Image src={BackArrowIcon} className="img-fluid mx-2" />
            <div>Previous</div>
            <div></div>{" "}
          </Pagination.Prev>
          {controlledPageCount && (
            <div className="mx-3">
              <select onChange={(e) => gotoPage(Number(e.target.value) - 1)} value={pageIndex + 1} className="native-select">
                {pages.map((item, index) => (
                  <option key={`page-option-${index}`} value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
          )}
          {/* {range.map((item, i) => (
            <Pagination.Item
              key={"pageNumber-" + i}
              onClick={() => gotoPage(item - 1)}
              active={pageIndex + 1 === item}
              className="mx-2"
            >
              {item}
            </Pagination.Item>
          ))} */}
          <Pagination.Next
            className="mx-3"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {" "}
            <div></div>
            <div>Next</div>{" "}
            <Image src={NextArrowIcon} className="img-fluid mx-2" />
          </Pagination.Next>
        </Pagination>
      ) : (
        ""
      )}
    </div>
  );
};

export default TableComponent;
