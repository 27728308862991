import React, { useRef } from "react";
import { useState, useMemo, useCallback } from "react";
import TableComponent from "../../components/tableComponent/TableComponent";
import { formatDate, useBoolean } from "../../utility";
import {
  Container,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  addStaff,
  deleteStaffById,
  getStaffList,
  updateStaffById,
} from "../../services/staff";
import ModalComponent from "../../components/ModalComponent";
import AddStaff from "./AddStaff";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import "./staff.scss";
import { toast } from "react-toastify";
import EditStaff from "./EditStaff";

const Staff = () => {
  const [isVisible, show, hide] = useBoolean(false);
  const [typeOf, setTypeOf] = useState("add");
  const [editData, setEditData] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const pageCount = useRef(0);
  const pageIndex = useRef(0);
  const pageSize = useRef(10);
  const totalUsers = useRef(0);
  const exitState = useRef(null);
  const raveState = useRef(false);

  const getStaffUsers = useCallback((p, s) => {
    if (exitState.current !== p || raveState.current) {
      setLoading(true);
      exitState.current = p;
      pageIndex.current = p;
      pageSize.current = s;
      getStaffList(p + 1, s).then((res) => {
        if (res.api_success) {
          setUsersList(res.data.staff.docs);
          pageCount.current = res.data.staff.totalPages;
          totalUsers.current = res.data.staff.totalDocs;
          raveState.current = false;
          setLoading(false);
        }
      });
    }
  }, []);

  const fetchData = useCallback(
    (e) => {
      getStaffUsers(e.pageIndex, pageSize.current);
    },
    [getStaffUsers]
  );

  // Custom dropdown list on row action
  const MyCell = useCallback(
    (props) => {
      return (
        props.row.original.email !== "admin@livetake.com" && (
          <Dropdown>
            <Dropdown.Toggle className="m-0 p-0 main-dropdown">
              <BsThreeDotsVertical style={{ zIndex: 1, color: "black" }} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu-dropdown">
              <Dropdown.Item
                onClick={() => {
                  setTypeOf("edit");
                  setEditData(props.row.original);
                  show();
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setTypeOf("delete");
                  setEditData(props.row.original);
                  show();
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
      );
    },
    [show]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "text-truncate",
        Cell: (e) => {
          return e.row.original.firstName || e.row.original.lastName
            ? e.row.original.firstName + " " + e.row.original.lastName
            : "N/A";
        },
      },
      {
        Header: "Email Id",
        accessor: "email",
        className: "text-truncate",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: (e) => {
          return e.row.original.createdAt
            ? formatDate(e.row.original.createdAt)
            : "N/A";
        },
      },
      {
        Header: " ",
        Cell: MyCell,
        className: "p-0",
        width: 35,
      },
    ],
    [MyCell]
  );

  // Save Staff
  const saveStaff = useCallback(
    (values) => {
      setSaveLoading(true);
      addStaff(values).then((res) => {
        if (res.api_success) {
          raveState.current = true;
          getStaffUsers(0, pageSize.current);
          toast.success(res.message);
          hide();
        }
        setSaveLoading(false);
      });
    },
    [getStaffUsers, hide]
  );

  // Update Staff
  const updateStaff = useCallback(
    (id, value) => {
      setSaveLoading(true);
      updateStaffById(id, value).then((res) => {
        if (res.api_success) {
          raveState.current = true;
          getStaffUsers(0, pageSize.current);
          toast.success(res.message);
          hide();
        }
        setSaveLoading(false);
      });
    },
    [getStaffUsers, hide]
  );

  const handleDelete = useCallback(() => {
    setSaveLoading(true);
    deleteStaffById(editData._id).then((res) => {
      if (res.api_success) {
        raveState.current = true;
        getStaffUsers(0, pageSize.current);
        toast.success(res.message);
        setEditData(null);
        hide();
      }
      setSaveLoading(false);
    });
  }, [editData, getStaffUsers, hide]);

  return (
    <Container>
      <Row>
        <ModalComponent
          show={isVisible}
          onHide={() => hide()}
          centered
          className="add-admin-model"
          component={
            typeOf === "add" ? (
              <AddStaff
                onHide={() => hide()}
                saveData={(e) => saveStaff(e)}
                loading={saveLoading}
              />
            ) : typeOf === "edit" ? (
              <EditStaff
                onHide={() => {
                  hide();
                }}
                data={editData}
                saveData={(e, i) => updateStaff(e, i)}
                loading={saveLoading}
              />
            ) : typeOf === "delete" ? (
              <ConfirmPop
                onHide={() => hide()}
                title={"Do you want to delete this staff user ?"}
                handleFunc={(e) => handleDelete(e)}
                loading={saveLoading}
              />
            ) : (
              ""
            )
          }
        />
        <Col className="mb-2 d-flex align-items-center justify-content-start">
          <h4 className="fw-700">
          Total Staff: <span style={{ color: "#39B54A" }}>{totalUsers.current}</span>
          </h4>
          <div className="d-flex align-items-center justify-content-end ms-auto">
            <Button className="d-flex align-items-center ms-3" onClick={() => { show(); setTypeOf("add");}}>
              <FiPlusCircle className="me-2" />
              Add Staff User
            </Button>
          </div>
        </Col>
        <Col md="12">
          <TableComponent
            data={usersList}
            columns={columns}
            loading={loading}
            fetchData={(e) => fetchData(e)}
            pageCount={pageCount.current}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Staff;

const ConfirmPop = ({ onHide, title, handleFunc, loading }) => {
  return (
    <Card className="border-0 p-4">
      <h3 className="text-center">{title}</h3>
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Button className="mx-3 px-5" onClick={() => handleFunc()}>
          {loading ? (
            <Spinner size="sm" animation="border" role="status"></Spinner>
          ) : (
            "Yes"
          )}
        </Button>
        <Button className="mx-3 px-5" variant="danger" onClick={() => onHide()}>
          No
        </Button>
      </div>
    </Card>
  );
};
