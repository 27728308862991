import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "../../components/formComponents/Input";
import { addSocialUserRules } from "../../utility";

const AddSocialUserDetails = ({ onHide, submitData, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: addSocialUserRules,
    defaultValues: {
      firstName: "",
      lastName: "",
      instagramHandle: "",
      twitterHandle: "",
    },
  });

  //Submit Data to parents component
  const Submit = (values) => {
    submitData(values);
  };

  return (
    <div className="input-main d-flex flex-column justify-content-center align-items-center" style={{width: 'auto'}}>
      <h4 className="mb-3">Add New Username</h4>
      <form onSubmit={handleSubmit(Submit)}>
        <div className=" pb-2 mt-2 mx-auto">
          <div className="input-admin mt-2">
            <label className="h4 mb-2 fs-20">
              First Name<span className="text-danger">*</span>
            </label>
            <Input
              className="admin-input"
              type="text"
              placeholder="First name"
              error={errors?.firstName?.message}
              {...register("firstName")}
            />
          </div>
          <div className="input-admin mt-2">
            <label className="h4 mb-2 fs-20">
              Last Name<span className="text-danger">*</span>
            </label>
            <Input
              className="admin-input"
              type="text"
              placeholder="Last name"
              error={errors?.lastName?.message}
              {...register("lastName")}
            />
          </div>
          <div className="input-admin mt-2">
            <label className="h4 mb-2 fs-20">
              Instagram Handle<span className="text-danger">*</span>
            </label>
            <Input
              className="admin-input"
              type="text"
              placeholder="Instagram Handle"
              error={errors?.instagramHandle?.message}
              {...register("instagramHandle")}
            />
          </div>
          <div className="input-admin mt-2">
            <label className="h4 mb-2 fs-20">
              Twitter Handle<span className="text-danger">*</span>
            </label>
            <Input
              className="admin-input"
              type="text"
              placeholder="Twitter Handle"
              error={errors?.twitterHandle?.message}
              {...register("twitterHandle")}
            />
          </div>
          <div className="d-flex justify-content-end mt-4 align-items-center">
            <div className="me-5">
              <Button
                className="text-muted mb-2 mt-2  border-0 bg-none cancle-btn"
                onClick={() => onHide()}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                className="mt-2 fs-20  btn btn-success rounded create-btn "
                disabled={loading}
              >
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner
                      size="sm"
                      animation="border"
                      role="status"
                      className="mx-3"
                    ></Spinner>
                  </div>
                ) : (
                  "Create"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSocialUserDetails;
