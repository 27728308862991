import { AiOutlineDashboard, FiUsers, FiUserX , AiOutlineUsergroupAdd, IoShareSocialOutline } from "./images";

export const sidebarLinks = [
  {
    name: "Dashboard",
    link: "/",
    img: <AiOutlineDashboard />,
    role: "ANY",
  },
  {
    name: "Users",
    link: "/users",
    img: <FiUsers />,
    role: "ANY",
  },
  {
    name: "Reported ",
    link: "/reported-users",
    img: <FiUserX />,
    role: "ANY",
  },
  {
    name: "Staff",
    link: "/staff",
    img: <AiOutlineUsergroupAdd />,
    role: "OWNER",
  },
  {
    name: "Usernames",
    link: "/usernames",
    img: <IoShareSocialOutline />,
    role: "ANY",
  },
];
