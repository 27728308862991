import moment from "moment";

export const handleApi = async (promise) => {
  return promise
    .then((result) => result.data)
    .catch((error) => Promise.resolve(error.response.data));
};

export const removeEmoji = (str) => {
  let strCopy = str;
  const emojiKeycapRegex = /[\u0023-\u0039]\ufe0f?\u20e3/g;
  const emojiRegex = /\p{Extended_Pictographic}/gu;
  const emojiComponentRegex = /\p{Emoji_Component}/gu;
  if (emojiKeycapRegex.test(strCopy)) {
    strCopy = strCopy.replace(emojiKeycapRegex, "");
  }
  if (emojiRegex.test(strCopy)) {
    strCopy = strCopy.replace(emojiRegex, "");
  }
  if (emojiComponentRegex.test(strCopy)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const emoji of strCopy.match(emojiComponentRegex) || []) {
      if (/[\d|*|#]/.test(emoji)) {
        continue;
      }
      strCopy = strCopy.replace(emoji, "");
    }
  }

  return strCopy;
};

export const labeInterestArr = (a) => {
  let n = [];
  a.forEach((i) => {
    n.push({ label: i.interestEmoji + " " + i.interestName, value: i.uid });
  });
  return n;
};

export const labelArr = (a, l, v) => {
  let n = [];
  a.forEach((i) => {
    n.push({ label: i[l], value: i[v] });
  });
  return n;
};

export const formatPhone = (number) => {
  return number
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
};

export const formatDate = (date, format = "MM/DD/YYYY") => {
  return moment(date).format(format);
};

export const filterData = (name, value, data) =>
  (data || []).filter(
    (item) =>
      !name || !item[name] || item[name].trim().toLowerCase().includes(value)
  );

export const filterArray = (a, b) => {
  let newArr = [];
  a.forEach((obj, j) => {
    if (b.includes(obj)) {
      newArr.push(obj);
    }
  });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;
};

export const dynamicSort = (arr) => {
 return arr.sort((a, b) =>  a.email.localeCompare(b.email));
};
