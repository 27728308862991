import axios from "axios";
import { autoLogin } from "../services";
import { Storage } from "./storage";

const baseURL = process.env.REACT_APP_API;
const createAxiosClient = () => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json,text/plain";

    const accessToken = Storage.getAccessToken();
    config.headers.Authorization = `${accessToken}`;
    return config;
  });

  instance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if(error.response && error.response.status === 401 && !originalRequest._retry){
      originalRequest._retry = true;
      const data = { refreshToken : Storage.getRefreshToken() }
      try{
        autoLogin(data).then((res) => {
          if(res.success){
            localStorage.setItem('access_token' , JSON.stringify(res.data.accessToken));
            localStorage.setItem('refresh_token' , JSON.stringify(res.data.refreshToken));
          }else {
            localStorage.clear();
          }
          window.location.reload();
        })
      }catch(err){
        return Promise.reject(err)
      }
    }
    return Promise.reject(error);
  });

  return instance;
};



export default createAxiosClient();
