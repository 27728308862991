import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Col,
  Row,
  Navbar,
  Button,
  NavDropdown,
  Tab,
  Tabs,
  Modal,
  Form,
} from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import Avatar from "react-avatar";
import moment from "moment";
import { toast } from "react-toastify";

import Loader from "../../components/loader";
import ConfirmDialog from "../../components/ConfirmDialog";
import LiveTakes from "./UserLiveTakes";
import Takes from "./UserTakes";

import {
  getUserById,
  changeStatus,
  deleteUsers,
  reactivateUser,
  editUsername,
} from "../../services";

import "./Users.scss";

const UserDetails = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [actionProps, setActionProps] = useState(null);

  const [show, setShow] = useState(false);
  const [username, setUsername] = useState();
  const [isError, setIsError] = useState(false);

  const handleClose = () => {
    setShow(false);
    setUsername(user?.userName);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (id, name) => {
    if (/^[A-Za-z0-9_.]+$/.test(name)) {
      const result = await editUsername(id, name);
      if (result && result.api_success) {
        handleClose();
        getUserDetails();
      }
    } else {
      setIsError(true);
      return false;
    }
  };

  const getUserDetails = useCallback(async () => {
    setLoading(true);
    const result = await getUserById(uid);
    if (result && result.data && result.data.user) {
      setUser(result.data.user);
      setUsername(result?.data?.user?.userName);
    }
    setLoading(false);
  }, [uid]);

  const handleStatusChange = useCallback(
    async (data) => {
      setProcessing(true);
      const result = await changeStatus(uid, data);
      if (result && result.api_success) {
        setUser({ ...user, ...data });
        setUsername(user?.userName);
        toast.success(result.message);
        setShowConfirmation(false);
      }
      setProcessing(false);
    },
    [uid, user]
  );

  const handleDelete = useCallback(async () => {
    setProcessing(true);
    const result = await deleteUsers(uid);
    if (result && result.api_success) {
      setUser({ ...user, isDeleted: true });
      toast.success(result.message);
      setShowConfirmation(false);
    }
    setProcessing(false);
  }, [uid, user]);

  const handleUndoDelete = useCallback(async () => {
    setProcessing(true);
    const result = await reactivateUser(uid);
    if (result && result.api_success) {
      setUser({ ...user, isDeleted: false });
      toast.success(result.message);
      setShowConfirmation(false);
    }
    setProcessing(false);
  }, [uid, user]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Navbar
        expand="lg"
        variant="light"
        bg="light"
        className="mb-3 px-3 user-details-navbar"
      >
        <Button
          variant="light"
          className="d-flex align-items-center ps-0 btn--back"
          onClick={() => navigate(`/users`)}
        >
          <BiArrowBack className="me-1" /> Back
        </Button>
        <h6 className="my-0 ms-2">User Details</h6>
        {!loading && user && (
          <NavDropdown
            id="user-details-more-menu"
            title="Actions"
            menuVariant="dark"
            className="ms-auto d-flex align-items-center"
            align="end"
            style={{ zIndex: 1 }}
          >
            <NavDropdown.Item
              as={Button}
              onClick={() => {
                setActionProps({
                  message: "Are you sure you want to change verified status?",
                  action: () =>
                    handleStatusChange({ isVerified: !user.isVerified }),
                });
                setShowConfirmation(true);
              }}
            >
              Mark as {user.isVerified ? "Unverified" : "Verified"}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Button}
              onClick={() => {
                setActionProps({
                  message: "Are you sure you want to change goated status?",
                  action: () =>
                    handleStatusChange({ isGoated: !user.isGoated }),
                });
                setShowConfirmation(true);
              }}
            >
              Mark as {user.isGoated ? "Not Goated" : "Goated"}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {!user.isDeleted && (
              <NavDropdown.Item
                as={Button}
                className="text-danger"
                onClick={() => {
                  setActionProps({
                    message: "Are you sure you want to delete this user?",
                    action: handleDelete,
                  });
                  setShowConfirmation(true);
                }}
              >
                Delete User
              </NavDropdown.Item>
            )}
            {user.isDeleted && (
              <NavDropdown.Item
                as={Button}
                onClick={() => {
                  setActionProps({
                    message: "Are you sure you want to undo delete this user?",
                    action: handleUndoDelete,
                  });
                  setShowConfirmation(true);
                }}
              >
                Undo Delete User
              </NavDropdown.Item>
            )}
          </NavDropdown>
        )}
      </Navbar>
      <Row>
        <Col md={12} lg={4} className="mb-4">
          <Card className="py-4 px-4 border-0 shadow-sm">
            {!loading ? (
              <>
                {user ? (
                  <Row className="mx-auto w-100">
                    <Col
                      sm={12}
                      className="d-flex align-items-start justify-content-start px-0"
                    >
                      <Avatar
                        name={`${
                          user.profilePhoto
                            ? `${process.env.REACT_APP_S3}${user.profilePhoto}`
                            : user.userName
                        }`}
                        src={`${
                          user.profilePhoto
                            ? `${process.env.REACT_APP_S3}${user.profilePhoto}`
                            : null
                        }`}
                        size="75"
                        round={true}
                      />
                      <div className="ms-2">
                        <h5 className="mt-2 mb-1">
                          {!user.firstName && !user.lastName
                            ? "N/A"
                            : `${user.firstName} ${user.lastName}`}
                        </h5>
                        <h6 className="m-0 d-flex align-items-center justify-content-start">
                          {user.userName}
                          <span
                            className={`edit-badge ms-2 mt-1`}
                            title={"Edit"}
                            onClick={handleShow}
                          ></span>
                          <span
                            className={`verified-badge ms-2 mt-1 ${
                              user.isVerified ? "active" : ""
                            }`}
                            title={`${
                              user.isVerified ? "Verified" : "Unverified"
                            }`}
                          ></span>
                          <span
                            className={`goated-badge ms-2 mt-1 ${
                              user.isGoated ? "active" : ""
                            }`}
                            title={`${
                              user.isVerified ? "Goated" : "Not Goated"
                            }`}
                          ></span>
                        </h6>
                      </div>
                    </Col>
                    <Col
                      sm={6}
                      className="user-detail-row pt-2 pb-2 px-1 text-center border-0"
                    >
                      <span className="title">Followers</span>
                      <div style={{ fontSize: 24, fontWeight: 600 }}>
                        {user.followerCount ? user.followerCount : 0}
                      </div>
                    </Col>
                    <Col
                      sm={6}
                      className="user-detail-row pt-2 pb-2 px-1 text-center border-0"
                    >
                      <span className="title">Followings</span>
                      <div style={{ fontSize: 24, fontWeight: 600 }}>
                        {user.followingCount ? user.followingCount : 0}
                      </div>
                    </Col>
                    <Col sm={12} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Bio:</span>
                      <div>{user.bio ? user.bio : "--"}</div>
                    </Col>
                    <Col sm={12} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Date of Birth:</span>
                      <div>
                        {user.dob
                          ? moment(user.dob).format("MM/DD/YYYY")
                          : "--"}
                      </div>
                    </Col>
                    <Col sm={12} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Phone:</span>
                      <div>
                        {user.mobile
                          ? `${user.countryCode}-${user.mobile}`
                          : "--"}
                      </div>
                    </Col>
                    <Col sm={12} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Email:</span>
                      <div>{user.email ? user.email : "--"}</div>
                    </Col>
                    <Col sm={6} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Total LiveTakes:</span>
                      <div>
                        {user.liveTake && user.liveTake.totalCount
                          ? user.liveTake.totalCount
                          : 0}
                      </div>
                    </Col>
                    <Col sm={6} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Total Takes:</span>
                      <div>
                        {user.take && user.take.totalCount
                          ? user.take.totalCount
                          : 0}
                      </div>
                    </Col>
                    <Col sm={12} className="user-detail-row pt-2 pb-2 px-1">
                      <span className="title">Date Joined:</span>
                      <div>
                        {user.createdAt
                          ? moment(user.createdAt).format("MMM DD, YYYY")
                          : "--"}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="py-5 text-center">
                    User Details not found!
                  </div>
                )}
              </>
            ) : (
              <div className="py-5 text-center">
                <Loader />
              </div>
            )}
          </Card>
        </Col>
        <Col md={12} lg={8} className="mb-4">
          <Card className="py-4 px-4 border-0 shadow-sm">
            <Tabs
              defaultActiveKey="livetakes"
              id="user-livetakes-takes"
              className="mb-3 user-livetakes-takes"
              fill
            >
              <Tab eventKey="livetakes" title="LiveTakes">
                {!loading && user && <LiveTakes userId={uid} user={user} />}
              </Tab>
              <Tab eventKey="takes" title="Takes">
                {!loading && user && <Takes userId={uid} user={user} />}
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <ConfirmDialog
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        actionProps={actionProps}
        processing={processing}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Username</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {isError && !/^[A-Za-z0-9_.]+$/.test(username) && (
            <div className="mt-2" style={{ color: "red", fontSize: "12px" }}>
              Usernames must only include underscores (_) and periods (.) as
              special characters. Other special characters are not allowed.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSubmit(user?._id, username)}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserDetails;
