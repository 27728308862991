import React, { useEffect, useState, useCallback } from "react";
import { Card, Badge, Button } from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";
import { BiArrowBack, BiTrash } from "react-icons/bi";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { toast } from "react-toastify";

import Loader from "../../components/loader";
import ConfirmDialog from "../../components/ConfirmDialog";

import {
  getUserLiveTakes,
  getLiveTakeCommentsById,
  deleteLiveTakes,
  deleteTakesCommnetById,
} from "../../services";

const LiveTakes = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [liveTakes, setLiveTakes] = useState([]);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [confirmCommentDelete, setConfirmCommentDelete] = useState(null);
  const [pagination, setPagination] = useState(1);
  const [pageLimit] = useState(9);
  const [totalDocs, setTotalDocs] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [actionProps, setActionProps] = useState(null);

  const getLiveTakes = useCallback(
    async (reset = false) => {
      setLoading(true);
      const result = await getUserLiveTakes(
        userId,
        `page=${reset ? "1" : pagination}&limit=${pageLimit}`
      );
      if (result) {
        setLiveTakes(reset ? result.docs : [...liveTakes, ...result.docs]);
        setTotalDocs(result.totalDocs);
        if (result.hasNextPage && result.hasNextPage === true) {
          setPagination(result.nextPage);
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
      }
      setLoading(false);
    },
    [liveTakes, pageLimit, pagination, userId]
  );

  const getLiveTakeComments = useCallback(async () => {
    setCommentsLoading(true);
    const result = await getLiveTakeCommentsById(showComments);
    if (result && result.api_success) {
      setComments([...result.data.comments]);
    }
    setCommentsLoading(false);
  }, [showComments]);

  const handleOnDelete = useCallback(async () => {
    setDeleting(true);
    const result = await deleteLiveTakes(confirmDelete);
    if (result && result.api_success) {
      setConfirmDelete(null);
      getLiveTakes(true);
    }
    setDeleting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDelete]);

  const handleOnDeleteComment = async (commentId) => {
    setProcessing(true);
    const result = await deleteTakesCommnetById(commentId);
    if (result && result.api_success) {
      toast.success(result.message);
      setComments([]);
      getLiveTakeComments();
			setConfirmCommentDelete(false);
    }
    setProcessing(false);
  };

  useEffect(() => {
    getLiveTakes(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showComments !== null) {
      setComments([]);
      getLiveTakeComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComments]);

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        {liveTakes.map((lt, i) => (
          <Card
            key={`livetake-${i}`}
            className="card--livetake shadow border-0 mb-4 mx-2"
          >
            <Card.Body
              className={`px-2 pt-2 livetake-details ${
                showComments === lt._id || confirmDelete === lt._id
                  ? "hide"
                  : ""
              }`}
            >
              {lt.isLive && (
                <Badge bg="danger" className="live-badge">
                  Live
                </Badge>
              )}
              <Badge bg="dark" className="category-badge">
                {lt.category}
              </Badge>
              <Card.Title
                as="h6"
                className="text-center mb-3 position-relative px-4 text-truncate"
                title={lt.title}
              >
                <span className="px-3 text-truncate d-inline-block w-100">
                  {lt.title}
                </span>
              </Card.Title>
              <div className="d-grid align-items-center justify-content-center livetake-users">
                <span className="d-inline-flex flex-column align-items-center text-center mx-auto">
                  <Avatar
                    name={`${
                      lt.userId.profilePhoto
                        ? `${process.env.REACT_APP_S3}${lt.userId.profilePhoto}`
                        : lt.userId.userName
                    }`}
                    src={`${
                      lt.userId.profilePhoto
                        ? `${process.env.REACT_APP_S3}${lt.userId.profilePhoto}`
                        : null
                    }`}
                    size="45"
                    round={true}
                  />
                  <div className="mw-100">
                    <h6 className="text-center text-truncate mt-2">
                      {lt.userId.userName}
                    </h6>
                    <h6 className="d-flex align-items-center justify-content-center">
                      <span
                        className={`verified-badge mx-1 ${
                          lt.userId.isVerified ? "active" : ""
                        }`}
                        title={`${
                          lt.userId.isVerified ? "Verified" : "Unverified"
                        }`}
                      ></span>
                      <span
                        className={`goated-badge mx-1 ${
                          lt.userId.isGoated ? "active" : ""
                        }`}
                        title={`${
                          lt.userId.isVerified ? "Goated" : "Not Goated"
                        }`}
                      ></span>
                    </h6>
                  </div>
                </span>
                <span className="fst-italic">vs</span>
                <span className="d-inline-flex flex-column align-items-center text-center mx-auto">
                  <Avatar
                    name={`${
                      lt.opponentId.profilePhoto
                        ? `${process.env.REACT_APP_S3}${lt.opponentId.profilePhoto}`
                        : lt.opponentId.userName
                    }`}
                    src={`${
                      lt.opponentId.profilePhoto
                        ? `${process.env.REACT_APP_S3}${lt.opponentId.profilePhoto}`
                        : null
                    }`}
                    size="45"
                    round={true}
                  />
                  <div className="mw-100">
                    <h6 className="text-center text-truncate mt-2">
                      {lt.opponentId.userName}
                    </h6>
                    <h6 className="d-flex align-items-center justify-content-center">
                      <span
                        className={`verified-badge mx-1 ${
                          lt.opponentId.isVerified ? "active" : ""
                        }`}
                        title={`${
                          lt.opponentId.isVerified ? "Verified" : "Unverified"
                        }`}
                      ></span>
                      <span
                        className={`goated-badge mx-1 ${
                          lt.opponentId.isGoated ? "active" : ""
                        }`}
                        title={`${
                          lt.opponentId.isVerified ? "Goated" : "Not Goated"
                        }`}
                      ></span>
                    </h6>
                  </div>
                </span>
              </div>
              <div className="d-grid align-items-center justify-content-start text-center py-2 livetake-poll-options">
                <span
                  className={`px-2 ${
                    !lt.pollContents.left.title ? "text-muted" : ""
                  }`}
                  title={lt.pollContents.left.title}
                >
                  {lt.pollContents.left.title
                    ? lt.pollContents.left.title
                    : "N/A"}
                </span>
                <span
                  className={`px-2 ${
                    !lt.pollContents.right.title ? "text-muted" : ""
                  }`}
                  title={lt.pollContents.right.title}
                >
                  {lt.pollContents.right.title
                    ? lt.pollContents.right.title
                    : "N/A"}
                </span>
              </div>
              <div className="d-grid align-items-center justify-content-start text-center livetake-poll-result">
                <span className="px-2">
                  {lt.pollContents.left.percentage}%{" "}
                  <span>{lt.pollContents.left.voteCount}</span>
                </span>
                <span className="px-2">
                  {lt.pollContents.right.percentage}%{" "}
                  <span>{lt.pollContents.right.voteCount}</span>
                </span>
              </div>
              <div className="livetake-attributes px-3 py-2">
                <span className="d-grid py-1">
                  <span>Duration:</span>
                  <span>{(lt.durationInSec / 60).toFixed(2)} Mins</span>
                </span>
                <span className="d-grid py-1">
                  <span>Visibility:</span>
                  <span className="text-capitalize">{lt.visibility}</span>
                </span>
                <span className="d-grid py-1">
                  <span>Type:</span>
                  <span className="text-capitalize">{lt.takeType}</span>
                </span>
                <span className="d-grid py-1">
                  <span>Date:</span>
                  <span className="text-capitalize">
                    {moment(lt.createdAt).format("MM/DD/YYYY")}
                  </span>
                </span>
              </div>
              <div className="livetake-actions d-flex align-items-center justify-content-between px-0 pt-2">
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setShowComments(lt._id)}
                  className="btn--show-coments"
                >
                  Show Comments
                </Button>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setConfirmDelete(lt._id)}
                  className="btn--delete"
                  disabled={deleting}
                >
                  Delete LiveTake
                </Button>
              </div>
            </Card.Body>
            <Card.Body
              className={`px-2 pt-2 livetake-comments ${
                showComments !== lt._id ? "hide" : ""
              }`}
            >
              <Card.Title
                as="h6"
                className="text-center mb-3 position-relative d-flex align-items-center"
              >
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => setShowComments(null)}
                >
                  <BiArrowBack />
                </Button>
                <span className="px-3 text-truncate d-inline-block w-100">
                  Comments
                </span>
              </Card.Title>
              <div className="comments">
                {commentsLoading && (
                  <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <Loader />
                  </div>
                )}
                {!commentsLoading && comments.length <= 0 && (
                  <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <h6>No Comment!</h6>
                  </div>
                )}
                {!commentsLoading &&
                  comments.length > 0 &&
                  comments.map((comment, commentIndex) => (
                    <div
                      key={`comment-${commentIndex}`}
                      className="comment--card"
                    >
                      <div>
                        <span>
                          <Avatar
                            name={`${
                              comment.userId.profilePhoto
                                ? `${process.env.REACT_APP_S3}${comment.userId.profilePhoto}`
                                : comment.userId.userName
                            }`}
                            src={`${
                              comment.userId.profilePhoto
                                ? `${process.env.REACT_APP_S3}${comment.userId.profilePhoto}`
                                : null
                            }`}
                            size="25"
                            round={true}
                          />
                        </span>
                        <span className="comment">
                          <small className="text-muted">
                            {comment.userId.userName}
                          </small>
                          <div className="mt-1">{comment.comment}</div>
                        </span>
                        <span>
                          <Button
                            variant="default"
                            size="sm"
                            className="py-0 px-0"
                            // onClick={() => setConfirmCommentDelete(comment._id)}
                            onClick={() => {
                              setActionProps({
                                message:
                                  "Are you sure you want to delete this comment?",
                                action: () =>
                                  handleOnDeleteComment(comment._id),
                                type: "danger",
                              });
                              setConfirmCommentDelete(true);
                            }}
                          >
                            <BiTrash className="text-danger" size={16} />
                          </Button>
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between text-muted mt-1">
                        <span>
                          <Badge
                            bg="light"
                            text="dark"
                            className="me-2 take-emoji"
                          >
                            {comment.likeCount > 0 ? (
                              <AiFillHeart className="text-danger" size={16} />
                            ) : (
                              <AiOutlineHeart
                                className="text-danger"
                                size={16}
                              />
                            )}{" "}
                            {comment.likeCount}
                          </Badge>
                        </span>
                        <small>
                          {moment(comment.createdAt).format(
                            "MM/DD/YYYY HH:MM:SS A"
                          )}
                        </small>
                      </div>
                    </div>
                  ))}
              </div>
            </Card.Body>
            <Card.Body
              className={`px-2 pt-2 livetake-delete-confirm ${
                confirmDelete !== lt._id ? "hide" : ""
              }`}
            >
              <Card.Title
                as="h6"
                className="text-center mb-3 position-relative d-flex align-items-center"
              >
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => setConfirmDelete(null)}
                  disabled={deleting}
                >
                  <BiArrowBack />
                </Button>
                <span className="px-3 text-truncate d-inline-block w-100 text-danger">
                  Delete LiveTake
                </span>
              </Card.Title>
              <div className="delete-confirm">
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                  <BiTrash className="text-danger" size={48} />
                  <h6 className="my-4">Are you sure?</h6>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleOnDelete()}
                    disabled={deleting}
                  >
                    Yes, Delete!
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      {loading && (
        <div className="py-3 text-center">
          <Loader />
        </div>
      )}
      {totalDocs > 0 && (
        <div className="text-center">
          <small>
            Showing results {liveTakes.length} out of {totalDocs}
          </small>
        </div>
      )}
      {!loading && totalDocs === 0 && (
        <div className="text-center">No LiveTake Found!</div>
      )}
      {!loading && loadMore && (
        <div className="text-center">
          <Button variant="default" size="sm" onClick={() => getLiveTakes()}>
            Load More
          </Button>
        </div>
      )}
      <ConfirmDialog
        show={confirmCommentDelete}
        onHide={() => setConfirmCommentDelete(false)}
        actionProps={actionProps}
        processing={processing}
      />
    </>
  );
};

export default LiveTakes;
