import { handleApi } from "../utility";
import axiosClient from "../utility/axiosClient";

const BASE = `dashboard`;

export async function totalUsers() {
    return await handleApi(axiosClient.get(`${BASE}/total-users`));
  }


export async function activeUsers(fromDate,toDate,type) {
    return await handleApi(axiosClient.get(`${BASE}/active-users?fromDate=${fromDate}&toDate=${toDate}&type=${type}`));
  }