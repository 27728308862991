import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const loginRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .email("Email must be a valid email .")
      .required("Email is a required."),
    password: Yup.string().required("Password is a required."),
  }).required()
);

export const passwordRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .email("Email must be a valid email .")
      .required("Email is a required."),
  }).required()
);

export const changePasswordRules = yupResolver(
  Yup.object({
    oldPassword: Yup.string().required("Old Password is a required."),
    newPassword: Yup.string()
      .required("New Password is a required.")
      .min(8, "New Password must be 8 characters minimum.")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
        "New Password must contain a number ,upper case & special character."
      )
      .test(
        "passwords-match",
        "New password is not same as old password.",
        function (value) {
          return this.parent.oldPassword !== value;
        }
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is a required.")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm password is not match with new password."
      ),
  })
);

export const staffRules = yupResolver(
  Yup.object({
    firstName: Yup.string().required("First name is a required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().email().required("Email is a required."),
    password: Yup.string()
      .required("Password is a required.")
      .min(8, "Password must be 8 characters minimum.")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
        "Password must contain a number ,upper case & special character."
      ),

    role: Yup.object().nullable().required("Permission is a required."),
  }).required()
);

export const editStaffRules = yupResolver(
  Yup.object({
    firstName: Yup.string().required("First name is a required."),
    lastName: Yup.string().required("Last name is required."),
    role: Yup.object().nullable().required("Permission is a required."),
  }).required()
);

export const addSocialUserRules = yupResolver(
  Yup.object({
    firstName: Yup.string().required("First name is a required."),
    lastName: Yup.string().required("Last name is required."),
    instagramHandle: Yup.string().required("Field is a required."),
    twitterHandle: Yup.string().required("Field is required."),
  }).required()
);
