import { Modal, Button } from "react-bootstrap";

const ModalComponent = (props) => {
  return (
    <Modal show={props.show} aria-labelledby="confirmation-dialog" centered>
      <Modal.Header closeButton={false} className="mx-auto border-0">
        <Modal.Title id="confirmation-dialog" className="text-center">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h4>
          {props.actionProps && props.actionProps.message
            ? props.actionProps.message
            : ""}
        </h4>
      </Modal.Body>
      {props.actionProps && (
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          {props.actionProps.action && (
            <Button
              onClick={props.actionProps.action}
              variant={
                props.actionProps && props.actionProps.type
                  ? props.actionProps.type
                  : "success"
              }
              className="px-5"
              disabled={props.processing}
            >
              Yes
            </Button>
          )}
          <Button
            onClick={props.onHide}
            variant="default"
            size="sm"
            disabled={props.processing}
          >
            Cancel
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ModalComponent;
