import { Button, Spinner,  } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Input from "../../components/formComponents/Input";
import { editStaffRules } from "../../utility";

const EditStaff = ({ onHide , saveData , loading , data }) => {

    const permission = [{label: 'Maintainer' , value : 'MAINTAINER'} , {label: 'Owner' , value : 'OWNER'}];

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: editStaffRules,
    defaultValues: {
      firstName : data !== undefined && data.firstName ? data.firstName : '',
      lastName : data !== undefined && data.lastName ? data.lastName : '',
      email : data !== undefined && data.email ? data.email : '',
      role : data !== undefined && data.role ? permission.filter((obj) => obj.value === data.role)[0] : ''
    },
  });

    //Submit Data to parents component
    const Submit = values => {
      values.role = values.role.value;
      saveData(data._id , values);
    }
    
  
  return (
    <div className="input-main d-flex flex-column justify-content-center align-items-center ">
        <h4 className="mb-3">Edit Staff User</h4>
      <form onSubmit={handleSubmit(Submit)}>
      <div className=" pb-2 mt-2 mx-auto">
        <div className="input-admin mt-2">
          <label className="h4 mb-2 fs-20">
            First Name<span className="text-danger">*</span>
          </label>
          <Input
            className="admin-input"
            type="text"
            placeholder="First name"
            error={errors?.name?.firstName}
            {...register(('firstName'))}
          />
        </div>
        <div className="input-admin mt-2">
          <label className="h4 mb-2 fs-20">
            Last Name<span className="text-danger">*</span>
          </label>
          <Input
            className="admin-input"
            type="text"
            placeholder="Last name"
            error={errors?.name?.lastName}
            {...register(('lastName'))}
          />
        </div>
        <div className="input-admin  mt-2">
          <label className="h4 mb-2 fs-20  ">
            Email<span className="text-danger">*</span>
          </label>
          <Input
            className="admin-input"
            type="text"
            placeholder="admin@xyz.com"
            error={errors?.email?.message}
            disabled={data && data.email !== '' ? true : false}
            {...register(('email'))}
          />
        </div>
        <div className="input-admin  mt-2">
          <label className="fs-20 mb-2">
           Select Permissions<span className="text-danger">*</span>
          </label>
          <Controller
                control={control}
                name="role"
                render={({ field: { onChange, value, ref }}) => (
                  <Select
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    options={permission}
                    styles={AdminStyleSelect}
                    isSearchable={false}
                  />
                )}
            />
            <span className="d-block text-danger text-start py-1">{errors?.role?.message}</span>
        </div>
        <div className="d-flex justify-content-end mt-4 align-items-center">
        <div className="me-5">
          <Button
            className="text-muted mb-2 mt-2  border-0 bg-none cancle-btn"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
        </div>
        <div >
          <Button type="submit" className="mt-2 fs-20 btn btn-success rounded create-btn " disabled={loading}>
          {loading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner size="sm" animation="border" role="status" className="mx-3" ></Spinner>
                  </div>
                ) : (
                  "Update"
                )}
          </Button>
        </div>
        </div>
      </div>
      </form>
    </div>
  );
};

export default EditStaff;
export const AdminStyleSelect = {
    container: base => ({
      ...base,
      width: '348px',
      height: '50px',
      zIndex:999,
      fontFamily: '"Montserrat", sans-serif',
      border: '1.5px solid #efefef',
      borderRadius: '5px'
    }),
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
      borderRadius: '5px',
      zIndex:999, 
      height: '50px',
      fontFamily: '"Montserrat", sans-serif',
      textIndent: "15px",
      
    }),
    valueContainer: base => ({
      ...base,
      fontSize: '20px',
      overflow: 'visible',
      padding:0,
      zIndex:999,
      fontFamily: '"Montserrat", sans-serif',
      textIndent: "15px",
    }),
    placeholder: base => ({
      ...base,
      position: 'absolute',
      paddingLeft: '20px',
      color: '#94949e',
      fontSize: "20px",
      textIndent: "0px",
      fontWeight: 400,
      fontFamily: '"Montserrat", sans-serif',
      zIndex:999,
      whiteSpace : 'nowrap'
    }),
    indicatorSeparator : base => ({
      ...base,
      color: 'white',
      background: 'white',
    }),
    dropdownIndicator : base => ({
      ...base,
      color: ' #949494',
      fontSize : 16,
      width: '25',
      height: '25'
    }),
    option: base=>({
      ...base,
      color: '#949494',
      background:'none',
      fontWeight: 500,
      fontFamily: '"Montserrat", sans-serif',
      boxShadow: 'none',
      outline: 'none',   
      "&:hover": {
        color: "#007AFF"
      }
    })
  };