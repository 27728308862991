import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BiSearch, BiXCircle } from "react-icons/bi";
import {
  Table,
  InputGroup,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";

import Loader from "../loader";

const DataTable = (props) => {
  const resettingRef = useRef(false);
  const searchInputRef = useRef();
  const [loading, setLoading] = useState(false);
  const { fetchData, setTotal, refresh } = props;
  const [data, setData] = useState({});
  const [params, setParams] = useState({
    page: 1,
    limit: props.pageSize ? Number(props.pageSize) : 10,
    search: "",
  });

  const getData = useCallback(async () => {
    if (loading) return false;
    try {
      setLoading(true);
      let queryParams = [];
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          queryParams.push(`${key}=${value}`);
        }
      });
      const response = await fetchData(queryParams.join("&"));
      if (response !== null) {
        setData(response);
        setTotal(response.totalDocs ? response.totalDocs : 0);
      }
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [loading, params, fetchData, setTotal]);

  const handlePageClick = (page) => {
    resettingRef.current = true;

    setParams({
      ...params,
      page: page.selected + 1,
    });
  };

  const formatColumn = (item, column) => {
    if (column.custom) {
      return column.custom(item);
    } else {
      switch (column.type) {
        case "datetime":
          return (
            <span className="text-nowrap">
              {moment(_.get(item, column.key)).format("lll")}
            </span>
          );
        case "date":
          return (
            <span className="text-nowrap">
              {moment(_.get(item, column.key)).format("MMM DD, YYYY")}
            </span>
          );
        default:
          return (
            <span className={`d-block text-truncate`}>
              {_.get(item, column.key)}
            </span>
          );
      }
    }
  };

  const getRow = (item, index) => {
    return (
      <tr
        key={`table-row-${index}`}
        onClick={() => (props.onRowClick ? props.onRowClick(item) : null)}
      >
        <>
          {props.columns.map((column, index) => {
            return (
              <td
                key={`table-col-${index}`}
                className={`${column.align ? column.align : "text-left"}`}
                style={{ ...column.style }}
              >
                {formatColumn(item, column)}
              </td>
            );
          })}
          {props.actions && props.actions.length > 0 && (
            <td className="text-right">
              {props.actions.map((action, i) => {
                return (
                  <button
                    key={`action-btn-${i}`}
                    className={`btn btn-sm ml-2 ${
                      action.class ? action.class : "btn-info"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.onClick(item);
                    }}
                    title={action.tooltip ? action.tooltip : ""}
                  >
                    {action.icon}
                  </button>
                );
              })}
            </td>
          )}
        </>
      </tr>
    );
  };

  const renderRows = () => {
    return data.docs.map((item, index) => getRow(item, index));
  };

  const handleSearch = _.debounce((e) => {
    const value = e.target.value;
    setParams({ ...params, page: 1, search: value });
    resettingRef.current = true;
  }, 1000);

  const handleClearSearch = () => {
    resettingRef.current = true;
    setParams((state) => ({ ...state, page: 1, search: "" }));
    searchInputRef.current.value = "";
  };

  const handleOnFilter = (e) => {
    e.preventDefault();
    resettingRef.current = true;
    setParams((state) => ({
      ...state,
      [e.target.name]: e.target.value,
      page: 1,
    }));
  };

  const getFilterInput = (field) => {
    switch (field.type) {
      case "select":
        return (
          <FormGroup className="table-filter" controlId={`input-${field.name}`}>
            <FormLabel>{field.placeholder ? field.placeholder : ""}</FormLabel>

            <FormControl
              disabled={loading}
              name={field.name}
              as="select"
              onChange={handleOnFilter}
							value={params[field.name]}
            >
              <option value="">-- Select --</option>
              {field.options &&
                field.options.length > 0 &&
                field.options.map((x) => (
                  <option
                    key={`option-${x.value}`}
                    value={x.value}
                  >
                    {x.text}
                  </option>
                ))}
            </FormControl>
          </FormGroup>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (resettingRef.current) {
      resettingRef.current = false;
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (refresh > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex align-items-end justify-content-start pb-2 table-toolbar">
        {props.searchable && props.searchable === true && (
          <span className="mr-3">
            <InputGroup
              className={`mb-0 search-input--wrapper ${
                searchInputRef.current && searchInputRef.current.value
                  ? "hasValue"
                  : ""
              }`}
            >
              <InputGroup.Text id="search-input" className="text-primary">
                <BiSearch size="12" />
              </InputGroup.Text>
              <FormControl
                ref={searchInputRef}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-input"
                className="fs-12"
                onChange={handleSearch}
              />
              <InputGroup.Text
                className="text-danger"
                onClick={handleClearSearch}
                style={{
                  visibility:
                    searchInputRef.current && searchInputRef.current.value
                      ? "visible"
                      : "hidden",
                }}
              >
                <BiXCircle size="14" />
              </InputGroup.Text>
            </InputGroup>
          </span>
        )}
        <span className="ms-auto table-filters">
          {props.filters && props.filters.length > 0 && (
            <span className="d-flex align-items-center justify-content-start">
              {props.filters.map((x, i) => (
                <span key={`filters-${i}`} className="ms-3">
                  {getFilterInput(x)}
                </span>
              ))}
            </span>
          )}
        </span>
      </div>
      <Table className="data-table">
        <thead>
          <tr>
            {props.columns.map((item, index) => (
              <th key={`header-${index}`}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <>
            {loading && (
              <tr className="table-row-loading">
                <td
                  colSpan={props.columns.length + 1}
                  className="text-center py-5"
                >
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && data.docs && data.docs.length === 0 && (
              <tr>
                <td
                  colSpan={props.columns.length + 1}
                  className="text-center py-5"
                >
                  No records founds!
                </td>
              </tr>
            )}
            {!loading && data.docs && data.docs.length > 0 && renderRows()}
          </>
        </tbody>
      </Table>
      {data.docs && data.docs.length > 0 && (
        <div>
          <ReactPaginate
            previousLabel={<FiChevronLeft size={20} />}
            nextLabel={<FiChevronRight size={20} />}
            initialPage={0}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(data.totalDocs / params.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={(page) => handlePageClick(page)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default DataTable;
