import React, { useEffect, useState, useCallback } from "react";
import { Card, Badge, Button } from "react-bootstrap";
import moment from "moment";
import { BiArrowBack, BiTrash } from "react-icons/bi";

import Loader from "../../components/loader";

import { getUserTakes, deleteTakes } from "../../services";

function parseUnicode(str) {
  var r = /\\u([\d\w]{4})/gi;
  str = str.replace(r, function (match, grp) {
    return String.fromCharCode(parseInt(grp, 16));
  });
  return str;
}

const Takes = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [Takes, setTakes] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [pagination, setPagination] = useState(1);
  const [pageLimit] = useState(9);
  const [totalDocs, setTotalDocs] = useState(0);

  const getTakes = useCallback(
    async (reset = false) => {
      setLoading(true);
      const result = await getUserTakes(
        userId,
        `page=${reset ? "1" : pagination}&limit=${pageLimit}`
      );
      if (result) {
        setTakes(reset ? result.docs : [...Takes, ...result.docs]);
        setTotalDocs(result.totalDocs);
        if (result.hasNextPage && result.hasNextPage === true) {
          setPagination(result.nextPage);
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
      }
      setLoading(false);
    },
    [Takes, pageLimit, pagination, userId]
  );

  const handleOnDelete = useCallback(async () => {
    setDeleting(true);
    const result = await deleteTakes(confirmDelete);
    if (result && result.api_success) {
      setConfirmDelete(null);
      getTakes(true);
    }
    setDeleting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDelete]);

  useEffect(() => {
    getTakes(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        {Takes.map((lt, i) => (
          <Card
            key={`take-${i}`}
            className="card--take shadow border-0 mb-4 mx-2"
          >
            <Card.Body
              className={`px-2 pt-2 take-details ${
                confirmDelete === lt._id ? "hide" : ""
              }`}
            >
              {lt.isBacked && (
                <Badge bg="success" className="backed-badge">
                  BACKED
                </Badge>
              )}
              <Badge bg="dark" className="category-badge">
                {lt.category}
              </Badge>
              <Card.Title
                as="h6"
                className="text-center mb-3 position-relative px-4 py-2 text-truncate"
              ></Card.Title>
              <Card.Title
                as="h5"
                className="text-center mb-3 position-relative px-0 text-truncate"
                title={lt.takeStatement}
              >
                <span className="px-3 text-truncate d-inline-block w-100">
                  {lt.takeStatement}
                </span>
              </Card.Title>
              <div className="d-grid align-items-center justify-content-start text-center py-2 take-poll-options">
                <span
                  className={`px-2 ${
                    !lt?.pollContents?.left?.title ? "text-muted" : ""
                  }`}
                  title={lt?.pollContents?.left?.title}
                >
                  {lt?.pollContents?.left?.title
                    ? lt?.pollContents?.left?.title
                    : "N/A"}
                </span>
                <span
                  className={`px-2 ${
                    !lt?.pollContents?.right?.title ? "text-muted" : ""
                  }`}
                  title={lt?.pollContents?.right?.title}
                >
                  {lt?.pollContents?.right?.title
                    ? lt?.pollContents?.right?.title
                    : "N/A"}
                </span>
              </div>
              <div className="d-grid align-items-center justify-content-start text-center take-poll-result">
                <span className="px-2">
                  {lt?.pollContents?.left?.percentage}%{" "}
                  <span>{lt?.pollContents?.left?.voteCount}</span>
                </span>
                <span className="px-2">
                  {lt?.pollContents?.right?.percentage}%{" "}
                  <span>{lt?.pollContents?.right?.voteCount}</span>
                </span>
              </div>
              <div className="take-attributes px-3 py-2">
                <span className="d-grid py-1">
                  <span>Vote Window:</span>
                  <span>{lt.voteWindowInSec / 60} Mins</span>
                </span>
                <span className="d-grid py-1">
                  <span>Voting:</span>
                  <span>{lt.isVoteClosed ? "Closed" : "Open"}</span>
                </span>
                <span className="d-grid py-1">
                  <span>Date:</span>
                  <span className="text-capitalize">
                    {moment(lt.createdAt).format("MM/DD/YYYY")}
                  </span>
                </span>
              </div>
              <div className="take-emojies px-3 py-2">
                {lt.emojiReactions.map((emoji, index) => {
                  return (
                    <Badge
                      key={`emoji-${index}`}
                      bg="light"
                      text="dark"
                      className="me-2 take-emoji"
                    >
                      {parseUnicode(emoji.code)} {emoji.count}
                    </Badge>
                  );
                })}
              </div>
              <div className="take-actions d-flex align-items-center justify-content-between px-0 pt-2">
                <span></span>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setConfirmDelete(lt._id)}
                  className="btn--delete"
                  disabled={deleting}
                >
                  Delete Take
                </Button>
              </div>
            </Card.Body>
            <Card.Body
              className={`px-2 pt-2 take-delete-confirm ${
                confirmDelete !== lt._id ? "hide" : ""
              }`}
            >
              <Card.Title
                as="h6"
                className="text-center mb-3 position-relative d-flex align-items-center"
              >
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => setConfirmDelete(null)}
                  disabled={deleting}
                >
                  <BiArrowBack />
                </Button>
                <span className="px-3 text-truncate d-inline-block w-100 text-danger">
                  Delete Take
                </span>
              </Card.Title>
              <div className="delete-confirm">
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                  <BiTrash className="text-danger" size={48} />
                  <h6 className="my-4">Are you sure?</h6>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleOnDelete()}
                    disabled={deleting}
                  >
                    Yes, Delete!
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
      {loading && (
        <div className="py-3 text-center">
          <Loader />
        </div>
      )}
      {totalDocs > 0 && (
        <div className="text-center">
          <small>
            Showing results {Takes.length} out of {totalDocs}
          </small>
        </div>
      )}
      {!loading && totalDocs === 0 && (
        <div className="text-center">No Take Found!</div>
      )}
      {!loading && loadMore && (
        <div className="text-center">
          <Button variant="default" size="sm" onClick={() => getTakes()}>
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

export default Takes;
