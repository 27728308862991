import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "../../services";
import { changePasswordRules, SuccessTickImg } from "../../utility";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [passwordStatus, setPasswordStatus] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: changePasswordRules,
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  // Change Password in BE 
  const passwordChange = (data) => {
    changePassword(data).then((res) => {
      if (res.success) {
        setPasswordStatus(true);
        navigate("");
      } else {
        toast.error(res.error);
      }
    });
  };


  return (
    // Change password with Layout
    <div className="change-details-container">
      <div className="change-password-container">
        {passwordStatus ? (
          <div className="confirmation-container">
            <div className="form-inner">
              <div>
                <Image
                  src={SuccessTickImg}
                  alt="confirmation_icon"
                  className="confirmation-icon"
                />
              </div>
              <h3 className="confirmation-text">
                Password changed <br />
                successfully!
              </h3>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(passwordChange)}>
            <div className="form-inner">
              <h3 className="create-new-pwd">Create a new password</h3>
              <span className="d-block text-danger">
                {errors?.oldPassword?.message}
              </span>
              <span className="d-block text-danger">
                {errors?.newPassword?.message}
              </span>
              <span className="d-block text-danger">
                {errors?.confirmPassword?.message}
              </span>
              <div>
                <input
                  className={errors?.oldPassword?.message ? ' change-pwd-input border-danger' : 'change-pwd-input  border-success'}
                  type="password"
                  id="oldpassword"
                  placeholder="Old password"
                  {...register("oldPassword")}
                />
              </div>
              <div>
                <input
                 className={errors?.newPassword?.message ? ' change-pwd-input border-danger' : 'change-pwd-input  border-success'}
                  type="password"
                  id="newpassword"
                  placeholder="New password"
                  {...register("newPassword")}
                />
              </div>
              <div>
                <input
                  className={errors?.confirmPassword?.message ? ' change-pwd-input border-danger' : 'change-pwd-input  border-success'}
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm new password"
                  {...register("confirmPassword")}
                />
              </div>
              <button type="submit" className="change-pwd-btn">
                Change Password
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
