import { handleApi } from "../utility";
import axiosClient from "../utility/axiosClient";

const BASE = `staff`;

export async function getStaffList(pageIndex , pageLimit) {
  return await handleApi(axiosClient.get(`${BASE}${pageIndex !== null && pageLimit !== null ? `?page=${pageIndex}&limit=${pageLimit}` : ``}`));
}

export async function addStaff(data) {
    return await handleApi(axiosClient.post(`${BASE}` , data));
  }

  export async function updateStaffById(id , data) {
    return await handleApi(axiosClient.put(`${BASE}/${id}` , data));
  }

  export async function deleteStaffById(id) {
    return await handleApi(axiosClient.delete(`${BASE}/${id}`));
  }