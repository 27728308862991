import React from "react";
import LoginForm from "./views/auth/LoginForm";
import Home from "./views/home/Home";
import ChangePassword from "./views/changePassword/ChangePassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ForgotPassword from "./views/auth/ForgotPassword";
import Layout from "./views/layout/Layout";
import PageNotFound from "./views/pageNotFound/PageNotFound";
import ReportedUsers from "./views/reportedUsers/ReportedUsers";
import Staff from "./views/staff/Staff";
import PrivateRoute from "./PrivateRoute";
import Usernames from "./views/socialUsers/index";
import UserListing from "./views/users/UserListing";
import UsersDetails from "./views/users/UserDetails";

function App() {
  return (
    // Routes for the application
    <Router>
      <Routes>
        <Route path="/login" element={<LoginForm />}></Route>
        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>

        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />}></Route>
          <Route path="users">
            <Route path="" index element={<UserListing />} />
            <Route path="detail/:uid" element={<UsersDetails />} />
          </Route>
          <Route
            index
            path="/reported-users"
            element={<ReportedUsers />}
          ></Route>
          <Route
            index
            path="/staff"
            element={
              <PrivateRoute roles={["OWNER"]}>
                <Staff />
              </PrivateRoute>
            }
          ></Route>
          <Route index path="/usernames" element={<Usernames />}></Route>
          <Route path="/change-password" element={<ChangePassword />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
