import React, { useRef } from "react";
import { useState, useMemo, useCallback } from "react";
import TableComponent from "../../components/tableComponent/TableComponent";
import { formatDate } from "../../utility";
import { Container, Col, Row } from "react-bootstrap";
import { getReportedUsers } from "../../services/reportedUsers";
import { Link } from "react-router-dom";

const ReportedUsers = () => {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageCount = useRef(0);
  const pageIndex = useRef(0);
  const pageSize = useRef(10);
  const totalUsers = useRef(0);
  const exitState = useRef(null);

  const getAllUsers = useCallback((p, s) => {
    if (exitState.current !== p) {
      setLoading(true);
      exitState.current = p;
      pageIndex.current = p;
      pageSize.current = s;
      getReportedUsers(p + 1, s).then((res) => {
        if (res.api_success) {
          setUsersList(res.data.reportedUsers.docs);
          pageCount.current = res.data.reportedUsers.totalPages;
          totalUsers.current = res.data.reportedUsers.totalDocs;
          setLoading(false);
        }
      });
    }
  }, []);

  const fetchData = useCallback(
    (e) => {
      getAllUsers(e.pageIndex, pageSize.current);
    },
    [getAllUsers]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Reporter",
        accessor: "reporter",
        className: "text-truncate",
        Cell: (e) => {
          return e.row.original.reporter.userName !== null &&
            e.row.original.reporter.userName !== "" ? (
            <Link
              className="text-decoration-none text-dark"
              to={"/users/detail/" + e.row.original.reporter._id}
            >
              {e.row.original.reporter.userName}
            </Link>
          ) : (
            "N/A"
          );
        },
      },
      {
        Header: "Reported User",
        accessor: "reportedUser",
        className: "text-truncate",
        Cell: (e) => {
          return e.row.original.reportedUser.userName !== null ? (
            <Link
              className="text-decoration-none text-dark"
              to={"/users/detail/" + e.row.original.reportedUser._id}
            >
              {e.row.original.reportedUser.userName}
            </Link>
          ) : (
            "N/A"
          );
        },
      },
      {
        Header: "Message",
        accessor: "issue",
        Cell: (e) => {
          return e.row.original.issue ? e.row.original.issue : "N/A";
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (e) => {
          return e.row.original.date ? formatDate(e.row.original.date) : "N/A";
        },
      },
    ],
    []
  );

  return (
    <Container>
      <Row>
        <Col className="mb-2">
          <h4 className="fw-700">
            Total Reported Users:{" "}
            <span style={{ color: "#39B54A" }}>{totalUsers.current}</span>
            {}
          </h4>
        </Col>
        <Col
          md="12"
          className="d-flex justify-content-between align-items-center "
        >
          <div style={{ width: "287px" }}></div>
          <div></div>
        </Col>
        <Col md="12">
          <TableComponent
            data={usersList}
            columns={columns}
            loading={loading}
            fetchData={(e) => fetchData(e)}
            pageCount={pageCount.current}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ReportedUsers;
