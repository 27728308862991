import { Modal } from "react-bootstrap";

const ModalComponent = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        {props.component}
      </Modal.Body>
    </Modal>
  );
};
export default ModalComponent;
