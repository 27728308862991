import React, { useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ArrowImg, loginRules, Logo } from "../../utility";
import { login } from "../../services";
import { useForm } from "react-hook-form";
import "./LoginForm.scss";
import Input from "../../components/formComponents/Input";
import { Form } from "react-bootstrap";
import { isJwtExpired } from 'jwt-check-expiration';
import { useState } from "react";
import Loader from "../../components/loader";
import { Storage } from "../../utility/storage";
import { toast } from "react-toastify";

function LoginForm() {
  const navigate = useNavigate();
  const [loading , setLoading ] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: loginRules,
    defaultValues: {
      email: "",
      password: "",
    },
  });


  // Using token to navigate to home page if already logged In
  useEffect(() => {
    if (Storage.getAccessToken() && !isJwtExpired(Storage.getAccessToken())) {
      navigate('/');
    }
  }, [navigate]);



  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      login(values).then((response) => {
        if (response.status_code === 200) {
          Storage.setSession(response.data);
          setLoading(false);
          navigate("/");
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      });
    },
    [navigate]
  );

  return (
    <div className="login-pages">
      <div className="form-inner">
        <div>
          <img src={Logo} alt="logo" className="admin-logo" />
        </div>
        <h2 className="hey-there">
         Login
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            name="email"
            id="email"
            placeholder={"Email or Username"}
            {...register("email")}
            error={errors.email?.message}
          />
          <Input
            type="password"
            name="passsword"
            id="password"
            placeholder={"Password"}
            {...register("password")}
            error={errors.password?.message}
          />

          <button type="submit" disabled={loading} className="login-button">
            {loading ? <Loader className="dot-typing"  /> : <img src={ArrowImg} alt="arrow" className="arrow-style" /> }
          </button>
        </Form>
        <div className="link-inner">
          <Link className="forgot-password" to="/forgotpassword">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
