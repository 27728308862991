import React, { useState, useMemo, useCallback } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { FiUpload, FiPlusCircle } from "react-icons/fi";
import { toast } from "react-toastify";

import ModalComponent from "../../components/ModalComponent";
import AddSocialUserDetails from "./AddSocialUserDetails";

import DataTable from "../../components/dataTable/DataTable";

import { uploadCSV, userSocialList, addUsername } from "../../services/misc";

const Usernames = () => {
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        key: "name",
        title: "Name",
        type: "text",
        className: "text-truncate",
        custom: (item) => {
          return <span>{`${item.firstName} ${item.lastName}`}</span>;
        },
      },
      {
        key: "instagramHandle",
        title: "Insta Handle",
        type: "text",
        className: "text-truncate",
      },
      {
        key: "twitterHandle",
        title: "Twitter Handle",
        type: "text",
        className: "text-truncate",
      },
    ],
    []
  );

  // To Upload the CSV file
  const fileUploading = useCallback(
    (e) => {
      const { files } = e.target;

      if (files.length <= 0) {
        return false;
      }

      const formData = new FormData();

      // Update the formData object
      formData.append("document", files[0], files[0].name);
      uploadCSV(formData).then((res) => {
        if (res.api_success) {
          toast.success(res.message);
          setRefresh(refresh + 1);
        }
      });
    },
    [refresh]
  );

  // Save Username
  const saveUsername = useCallback(
    (values) => {
      setSaveLoading(true);
      addUsername(values).then((res) => {
        if (res.api_success) {
          toast.success(res.message);
          setRefresh(refresh + 1);
          setShowAddDialog(false);
        }
        setSaveLoading(false);
      });
    },
    [refresh]
  );

  return (
    <Container>
      <Row>
        <Col className="mb-2 d-flex align-items-center justify-content-start">
          <h4 className="fw-700">
            Total Usernames: <span style={{ color: "#39B54A" }}>{total}</span>
          </h4>
          <div className="d-flex align-items-center justify-content-end ms-auto">
            <label
              htmlFor="csv-file"
              className="btn btn-primary d-flex align-items-center"
            >
              <FiUpload className="me-2" />
              Upload File
              <input
                type="file"
                id="csv-file"
                className="visually-hidden"
                onChange={(e) => fileUploading(e)}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </label>
            <Button className="d-flex align-items-center ms-3" onClick={() => setShowAddDialog(true)}>
              <FiPlusCircle className="me-2" />
              Add New
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            columns={columns}
            setTotal={(total) => setTotal(total)}
            refresh={refresh}
            fetchData={userSocialList}
            searchable={true}
          />
        </Col>
      </Row>
      <ModalComponent
        show={showAddDialog}
        onHide={() => setShowAddDialog(false)}
        centered
        className="add-social-model"
        component={
          <AddSocialUserDetails
            onHide={() => setShowAddDialog(false)}
            submitData={(data) => saveUsername(data)}
            loading={saveLoading}
          />
        }
      />
    </Container>
  );
};

export default Usernames;
