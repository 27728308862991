import React from "react";
import PropTypes from "prop-types";

const Input = React.forwardRef(
  ({ type, name, divClass ,  className, disabled,  placeholder, error, ...rest }, ref) => {
    return (
      <div className={divClass} ref={ref}>
        <input
          type={type}
          autoComplete="off"
          name={name}
          id={name}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
        <span className="d-block text-danger text-start py-1">{error}</span>
      </div>
    );
  }
);
Input.defaultProps = {
  type : 'text',
  name: 'input-1',
  className: '',
  disabled : false,
  placeholder: ' Enter Input',
  error : '',
  divClass : 'd-inline-block'
}

Input.protoTypes = {
  type : PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled : PropTypes.bool,
  placeholder: PropTypes.string ,
  error : PropTypes.any,
  divClass : PropTypes.string,
};

export default Input;
