// import "./Layout.scss";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { CgLogOut } from "react-icons/cg";
import { RiLockPasswordLine } from "react-icons/ri";
import { Dropdown, ListGroup } from "react-bootstrap";
import { sidebarLinks } from "../../utility/constants";
import { useCallback } from "react";
import { Storage } from "../../utility/storage";

import Logo from "../../assets/img/Logo.svg";

const Layout = () => {
  const navigate = useNavigate();
  const logoutSession = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (Storage.getAccessToken() === null) {
      logoutSession();
    }
  }, [logoutSession]);

  return (
    // The Layout containing the Side panel and Header
    <>
      <header>
        <nav className="navbar bg-light fixed-top shadow-sm">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img alt="logo-icon" src={Logo} style={{ maxWidth: 230 }} />
            </NavLink>

            <Dropdown id="dropdown-item-button">
              <Dropdown.Toggle>
                {Storage.getSession().emailId} <FiChevronDown size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu align={"end"} className="py-2 px-2">
                <Dropdown.Item
                  as={Link}
                  to={`/change-password`}
                  className="py-2 mb-1"
                >
                  <RiLockPasswordLine size={18} /> Change Password
                </Dropdown.Item>
                <Dropdown.Divider className="p-0 m-0" />
                <Dropdown.Item
                  as="button"
                  onClick={() => logoutSession()}
                  className="py-2 text-danger mt-1"
                >
                  <CgLogOut size={18} /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </nav>
      </header>
      <div className="page--layout">
        <div className="sidebar shadow-sm">
          <div className="sidebar-menu">
            <ListGroup as="ul">
              {sidebarLinks.map((item, index) => (
                <ListGroup.Item
                  key={`sidebar-menu-${index}`}
                  as={NavLink}
                  to={item.link}
                >
                  <span className="me-2">{item.img}</span> {item.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
        <div className="page--content py-4 px-4">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default Layout;
