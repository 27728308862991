import React from 'react'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'

import highcharts3d from 'highcharts/highcharts-3d'
HC_more(Highcharts);
highcharts3d(Highcharts);

const PieCharts = ({title , seriesTitle , data , loading}) => {
    const options = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        credits: {
        enabled: false,
        },
        title: {
            text: title
        },
        yAxis: {
            min: 0,
            title: {
              text: "Active User",
            },
          },
        subtitle:false,
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45
            },
            series: {
                allowPointSelect: true,
                 dataLabels: {
                    enabled: false,
                    format: '{point.name}'
                },
                point: {
                    events: {
                        select: function () {
                            console.log("chart event",options);
                        }
                    }
                }
            }
        },
        series: [{
            name: seriesTitle,
            data: data,
        }]
      };

  return (
    <div className='position-relative'>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      />
      {data.length > 0 && <div className='position-absolute w-100' style={{bottom:'10px', textAlign: 'center' , fontSize: '12px' , fontWeight: 600}}>{seriesTitle+ '  '+ data[0][1]}</div>}
    </div>
  )
}

export default PieCharts
