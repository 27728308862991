import { handleApi } from "../utility";
import axiosClient from "../utility/axiosClient";

const BASE = `misc`;

export async function userSocialList(queryParams = "") {
  try {
    const result = await handleApi(
      axiosClient.get(
        `${BASE}/user-socials?${queryParams}`
      )
    );
    if (result.data && result.data.userSocials) {
      return result.data.userSocials;
    }

    return null;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function uploadCSV(data) {
  return await handleApi(axiosClient.post(`${BASE}/upload-csv`, data));
}

export async function addUsername(data) {
  return await handleApi(axiosClient.post(`${BASE}/user-social-info`, data));
}
